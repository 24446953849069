/* eslint-disable no-unused-vars */
import { useEffect, useMemo, lazy, Suspense } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'use-http';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { QuickViewCore } from "contexts/QuickViewProvider";
import { AssegnazioneAvanzataCore, useAssegnazioneAvanzata } from 'contexts/AssegnazioneAvanzataContext';
import { useBee } from 'contexts/Bee';
import moment from 'moment';

//component
import PageHeader from 'components/PageHeader';

//sections
const DettagliAttivita = lazy(() => import('./DettagliAttivita'));
const ListaOperatori = lazy(() => import('./ListaOperatori'));
const OperatoriAssegnati = lazy(() => import('./OperatoriAssegnati'));

//project import
import { openSnackbar, closeSnackbar } from 'store/reducers/snackbar';

//MUI
import
{
   Skeleton,
   Stack,
   Typography
} from '@mui/material'

const AssegnazioneAvanzataMarkup = () =>
{
   //recupero id operatore dai parametri url
   const {
      idContratto,
      idServizio,
      idGiorno,
      idTurno
   } = useParams();

   const {
      requesting,
      servizio,
      giorno,
      turni,
   } = useAssegnazioneAvanzata();

   //inizializzo hook dispatch
   const dispatch = useDispatch()

   //inizializzo l'oggetto per la chiamata fetch
   const {
      request,
      response,
      error
   } = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   //imposto l'oggetto con i links pre la breadcrumbs
   const breadcrumbs = useMemo(() =>
   {
      return [
         {
            label: "Pianificazione",
         },
         {
            label: "Contratti",
            path: "/pianificazione/contratti"
         },
         {
            label: `Contratto #${idContratto}`,
            path: `/pianificazione/contratti/${idContratto}/servizi/`
         },
         {
            label: "Servizio",
            path: `/pianificazione/contratti/${idContratto}/${idServizio}/assegnazione-risorse/servizio`
         },
         {
            label: "Assegnazione avanzata"
         }
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [requesting, servizio, idContratto, idServizio]);

   return (
      <>
         <PageHeader
            title="Assegnazione avanzata"
            breadcrumbs={breadcrumbs}
            afterTitle={requesting ? <Skeleton width={230} /> : (
               <Typography
                  variant="body"
               >
                  <Typography
                     component="strong"
                     variant="body"
                  >
                     {`${servizio?.contratto?.cliente?.ragione_sociale} - ${servizio?.contratto?.titolo}`}
                  </Typography>

                  {giorno && ` - ${moment(giorno?.giorno, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}

                  {idTurno && turni && ` - turno ${moment(turni[0]?.inizio_f1, 'HH:mm:ss').format('HH:mm')}/${moment(turni[0]?.fine_f1, 'HH:mm:ss').format('HH:mm')}${turni[0]?.inizio_f2 && turni[0]?.fine_f2 ? ` - ${moment(turni[0]?.inizio_f2, 'HH:mm:ss').format('HH:mm')}/${moment(turni[0]?.fine_f2, 'HH:mm:ss').format('HH:mm')}` : ''}`}

               </Typography>
            )}
         />
         <QuickViewCore>

            <Stack spacing={3}>
               <Suspense fallback={''}>
                  <DettagliAttivita />
               </Suspense>

               {
                  idTurno &&
                  <Suspense fallback={''}>
                     <ListaOperatori />
                  </Suspense>
               }

               <Suspense fallback={''}>
                  <OperatoriAssegnati />
               </Suspense>
            </Stack>

         </QuickViewCore>
      </>
   )
}

const AssegnazioneAvanzata = () =>
{
   return (
      <AssegnazioneAvanzataCore>
         <AssegnazioneAvanzataMarkup />
      </AssegnazioneAvanzataCore>
   )
}

export default AssegnazioneAvanzata;