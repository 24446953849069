/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */

import React, { useContext, createContext, useState, useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useFetch } from 'use-http';
import { useParams } from 'react-router';
import { useBee } from './Bee';

const assegnazioneAvanzataContext = createContext();

export function useAssegnazioneAvanzata()
{
   return useContext(assegnazioneAvanzataContext);
}

export function AssegnazioneAvanzataCore(props)
{
   const {
      children
   } = props;
   
   const {
      idContratto,
      idServizio,
      idGiorno,
      idTurno,
   } = useParams();

   const {
      forceReload,
      reload,
   } = useBee();

   //inizializzo l'oggetto per la chiamata fetch
   const fetcher = useFetch(process.env.REACT_APP_BASE_API_URL, { cachePolicy: 'no-cache' });

   const [unassigningIds, setUnassigningIds] = useState({});
   const [assigningIds, setAssigningIds] = useState({});
   const [confirmIds, setConfirmIds] = useState({});
   const [unconfirmIds, setUnconfirmIds] = useState({});
   const [requesting, setRequesting] = useState(false);
   const [servizio, setServizio] = useState(null);
   const [giorno, setGiorno] = useState(null);
   const [turni, setTurni] = useState([]);

   useEffect(() => {

      const fetchData = async () => {

         setRequesting(true);
         
         const data = await fetcher.request.get(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/`);

         if( fetcher.error )
         {
            setRequesting(false);
         }

         if (fetcher.response.ok)
         {
            setServizio(data);
            if( data?.giorni?.length > 0 )
            {
               const giorno = data.giorni.find((giorno) => parseInt(giorno.id) === parseInt(idGiorno));
               if( giorno )
               {
                  setGiorno(giorno);
                  setTurni(idTurno ? giorno.turni.filter((turno) => parseInt(turno.id) === parseInt(idTurno)) : giorno.turni);
               }
            }

            setRequesting(false);
            
         }

      }

      fetchData();

      if( reload )
      {
         fetchData();
      }

   }, [idContratto, idServizio, idGiorno, idTurno, reload]);

   const unassignOperatore = async (id) =>
   {
      setRequesting(true);

      setUnassigningIds({...unassigningIds, [id]: true});

      const container = {
         data: {
            operatore: {
               id: id
            }
         }
      };

      const unassigned = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori/`, container);

      if (fetcher.response.ok && unassigned)
      {
         setUnassigningIds({...assigningIds, [id]: false});
         forceReload();
         setRequesting(false);
      }

      if( fetcher.error )
      {
         setRequesting(false);
      }
   }

   const assignOperatore = async (id) =>
   {
      setRequesting(true);

      setAssigningIds({...assigningIds, [id]: true});

      const container = {
         data: {
            operatore: {
               id: id
            },
            add: true
         }
      };

      const assigned = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori/`, container);

      if (fetcher.response.ok && assigned)
      {
         setAssigningIds({...assigningIds, [id]: false});
         forceReload();
         setRequesting(false);
      }

      if( fetcher.error )
      {
         setRequesting(false);
      }

   }

   const confirmOperatore = async (id) => {

      setRequesting(true);

      setConfirmIds({...confirmIds, [id]: true});

      const container = {
         data: {
            operatore: {
               id: id
            },
            add: true
         }
      };

      const confirm = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori-conf/`, container);
      
      if (fetcher.response.ok && confirm)
      {
         setConfirmIds({...confirmIds, [id]: false});
         forceReload();
         setRequesting(false);
      }

      if( fetcher.error )
      {
         setRequesting(false);
      }

   }

   const unconfirmOperatore = async (id) => {

      setRequesting(true);

      setUnconfirmIds({...unconfirmIds, [id]: true});

      const container = {
         data: {
            operatore: {
               id: id
            }
         }
      };

      const unconfirm = await fetcher.request.post(`/api/pianificazione/contratti/${idContratto}/servizi/${idServizio}/giorni/${idGiorno}/turni/${idTurno}/operatori-conf/`, container);
      
      if (fetcher.response.ok && unconfirm)
      {
         setUnconfirmIds({...unconfirmIds, [id]: false});
         forceReload();
         setRequesting(false);
      }

      if( fetcher.error )
      {
         setRequesting(false);
      }

   }

   return (
      <assegnazioneAvanzataContext.Provider value={
         {
            //metodi
            unassignOperatore,
            assignOperatore,
            confirmOperatore,
            unconfirmOperatore,
            forceReload,
            assigningIds,
            setAssigningIds,
            unassigningIds,
            setUnassigningIds,
            confirmIds,
            setConfirmIds,
            unconfirmIds,
            setUnconfirmIds,
            servizio,
            giorno,
            turni,
            requesting
         }
      }>
         {children}
      </assegnazioneAvanzataContext.Provider>
   );
}

AssegnazioneAvanzataCore.propTypes = {
   idContratto: oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   idServizio: oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   idGiorno: oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   idTurno: oneOfType([
      PropTypes.string,
      PropTypes.number
   ])
};

AssegnazioneAvanzataCore.propTypes = {
   children: PropTypes.node,
};